import React from "react";
import { graphql, PageProps } from "gatsby";

import Layout from "@/components/Layout";
import Plate from "@/components/Plate";
import Hero from "@/components/Hero";

import { Text, Box, Container, Link } from "theme-ui";

export interface HomeProps {
  data: any;
}

const VisionPage: React.FC<HomeProps> = () => (
  <Layout>
    <Plate variant="styles.contentFrame">
      <Plate variant="styles.platePage">
        <Container sx={{ width: "100ch" }}>
        <Text as="h1" variant="pageTitle">
            Contact
          </Text>
          <Text as="p" variant="sectionBody">
            7th Floor, Metromed International Cardiac Centre Thondayad Bypass
            Road, opposite Mahindra Trucks & Bus, Palazhi, Kozhikode, Kerala
            673014
          </Text>

          <Box sx={{ pt: 3}}>
            <Text as="p" variant="sectionBody">
              <Link sx={{ fontSize: 1 }} href="tel:+914952430333">0495 - 2430333 (Landline)</Link>
            </Text>
            <Text as="p" variant="sectionBody">
              <Link sx={{ fontSize: 1 }} href="tel:+917994400333">07994400333 (Mobile)</Link>
            </Text>
          </Box>
        </Container>
      </Plate>
    </Plate>
  </Layout>
);

export default VisionPage;
